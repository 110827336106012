/* eslint-disable camelcase */
import { VueConstructor } from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  ConvertFile,
  CreateFolder,
  DeleteFolder,
  File,
  FileManagementState,
  Folder,
  MoveFile,
  RenameFile,
  RenameFolder,
  SearchParams,
  Type
} from '@/modules/fileManagement/types';

export const FETCH_FILES_BY_FOLDER = 'FETCH_FILES_BY_FOLDER';
export const FETCH_TREE_BY_CONSERVATORSHIP = 'FETCH_TREE_BY_CONSERVATORSHIP';
export const FETCH_FILE_BY_ID = 'FETCH_FILE_BY_ID';
export const FETCH_INBOX_FILES = 'FETCH_INBOX_FILES';
export const FETCH_INBOX_COUNT = 'FETCH_INBOX_COUNT';
export const FETCH_INBOX_BY_CONSERVATORSHIP = 'FETCH_INBOX_CONSERVATORSHIP';
export const FETCH_CREATOR_INBOX = 'FETCH_CREATOR_INBOX';
export const FETCH_OUTBOX = 'FETCH_FILES_OUTBOX';
export const FETCH_SEARCH = 'FETCH_FILES_SEARCH';

export const CREATE_FOLDER = 'CREATE_FOLDER';
export const RENAME_FOLDER = 'RENAME_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const MOVE_FOLDER = 'MOVE_FOLDER';

export const MOVE_FILE = 'MOVE_FILE';
export const RENAME_FILE = 'RENAME_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const CONVERT_FILE = 'CONVERT_FILE';

export const GET_TREE_BY_CONSERVATORSHIP = 'GET_TREE_BY_CONSERVATORSHIP';
export const GET_FILES_BY_FOLDER = 'GET_FILES_BY_FOLDER';
export const GET_INBOX_FILES = 'GET_INBOX_FILES';
export const GET_INBOX_COUNT = 'GET_INBOX_COUNT';
export const GET_INBOX_BY_CONSERVATORSHIP = 'GET_INBOX_CONSERVATORSHIP';
export const GET_OUTBOX_BY_CONSERVATORSHIP = 'GET_OUTBOX_BY_CONSERVATORSHIP';

export const baseURL = (): string => {
  if (process.env.VUE_APP_GATEWAY_API) {
    return process.env.VUE_APP_GATEWAY_API
  }

  return `https://api.betreuer-plattform.de`
}

const initialState = (): FileManagementState => ({
  inbox: {
    files: [],
    count: 0,
    conservatorship: {}
  },
  folder: {
    tree: {}
  },
  files: {},
  outbox: {}
});

const getters: GetterTree<FileManagementState, RootState> = {
  [GET_TREE_BY_CONSERVATORSHIP]: (state) => (conservatorship: string): Folder[] => {
    return state.folder.tree[conservatorship];
  },
  [GET_FILES_BY_FOLDER]: (state) => (folder: string) => {
    return state.files[folder] || [];
  },
  [GET_OUTBOX_BY_CONSERVATORSHIP]: (state) => (conservatorship: string): File[] => {
    return state.outbox[conservatorship] || [];
  },
  [GET_INBOX_BY_CONSERVATORSHIP]: (state) => (conservatorship: string): File[] => {
    return state.inbox.conservatorship[conservatorship] || [];
  },
  [GET_INBOX_FILES]: (state): File[] => {
    return state.inbox.files || [];
  },
  [GET_INBOX_COUNT]: (state): number => {
    return state.inbox.count || 0;
  }
};

const mutations: MutationTree<FileManagementState> = {
  SET_TREE(state: FileManagementState, { conservatorship, tree }: { conservatorship: string, tree: Folder[] }) {
    state.folder.tree = {
      ...state.folder.tree,
      [conservatorship]: tree
    };
  },
  SET_FILES(state: FileManagementState, { folder, files }: { folder: string, files: File[] }) {
    state.files = {
      ...state.files,
      [folder]: files
    };
  },
  SET_OUTBOX(state: FileManagementState, { conservatorship, files }: { conservatorship: string, files: File[] }) {
    state.outbox = {
      ...state.outbox,
      [conservatorship]: files
    };
  },
  SET_INBOX(state: FileManagementState, { files }: { files: File[] }) {
    state.inbox.files = [...files];
  },
  SET_INBOX_COUNT(state: FileManagementState, count: number) {
    state.inbox.count = count;
  },
  SET_INBOX_BY_CONSERVATORSHIP(state: FileManagementState, { conservatorship, files }: { conservatorship: string, files: File[] }) {
    state.inbox.conservatorship = {
      ...state.inbox.conservatorship,
      [conservatorship]: files
    };
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: VueConstructor): ActionTree<FileManagementState, RootState> => ({
  async [FETCH_TREE_BY_CONSERVATORSHIP]({ commit }, conservatorship) {
    try {
      const response = await Vue.axios.get(`${baseURL()}/file-service/folder-tree/${conservatorship}`, { responseType: 'json' });

      commit('SET_TREE', { tree: response.data, conservatorship });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_INBOX_FILES]({ commit }) {
    try {
      const response = await Vue.axios.get(`${baseURL()}/file-service/inbox`, { responseType: 'json' });

      commit('SET_INBOX', { files: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_SEARCH]({ commit }, search: SearchParams) {
    try {
      const response = await Vue.axios.get(`${baseURL()}/file-service/search`, { responseType: 'json', params: search });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_INBOX_BY_CONSERVATORSHIP]({ commit }, conservatorship) {
    try {
      const response = await Vue.axios.get(`${baseURL()}/file-service/inbox/${conservatorship}/files`, { responseType: 'json' });

      commit('SET_INBOX_BY_CONSERVATORSHIP', { files: response.data, conservatorship });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_INBOX_COUNT]({ commit }) {
    try {
      const response = await Vue.axios.get(`${baseURL()}/file-service/inbox/count`, { responseType: 'json' });

      commit('SET_INBOX_COUNT', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_CREATOR_INBOX]({ commit }) {
    try {
      const response = await Vue.axios.get(`${baseURL()}/file-service/inbox`, { responseType: 'json' });

      commit('SET_INBOX', { files: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_FILES_BY_FOLDER]({ commit }, folder) {
    try {
      const response = await Vue.axios.get(`${baseURL()}/file-service/folder/${folder}/files`, { responseType: 'json' });

      commit('SET_FILES', { files: response.data, folder });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_OUTBOX]({ commit }, conservatorship) {
    try {
      const response = await Vue.axios.get(`${baseURL()}/file-management/outbox/${conservatorship}/files`, { responseType: 'json' });

      commit('SET_OUTBOX', { files: response.data, conservatorship });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_FILE_BY_ID]({ commit }, { file, type }: { file: string, type: Type }) {
    try {
      let response

      if (type === Type.letter) {
        response = await Vue.axios.get(`api/file-management/file/${file}/${type}`, { responseType: 'blob' });
      } else {
        response = await Vue.axios.get(`${baseURL()}/file-service/file/${file}`, { responseType: 'blob' });
      }

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [CREATE_FOLDER]({ dispatch }, command: CreateFolder) {
    try {
      await Vue.axios.post(`${baseURL()}/file-service/folder/create`,  command, { responseType: 'json' });

      return await dispatch(FETCH_TREE_BY_CONSERVATORSHIP, command.conservatorship_id);
    } catch (error) {
      return { error };
    }
  },
  async [RENAME_FOLDER]({ dispatch }, command: RenameFolder) {
    try {
      await Vue.axios.post(`${baseURL()}/file-service/folder/rename`,  command, { responseType: 'json' });

      return {}
    } catch (error) {
      return { error };
    }
  },
  async [MOVE_FOLDER]({ dispatch }, command: MoveFile) {
    try {
      await Vue.axios.post(`${baseURL()}/file-service/folder/move`,  command, { responseType: 'json' });

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [DELETE_FOLDER]({ dispatch }, command: DeleteFolder) {
    try {
      await Vue.axios.post(`${baseURL()}/file-service/folder/delete`,  command, { responseType: 'json' });

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [RENAME_FILE]({ dispatch }, command: RenameFile) {
    try {
      await Vue.axios.post(`${baseURL()}/file-service/file/rename`,  command, { responseType: 'json' });

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [CONVERT_FILE]({ dispatch }, command: ConvertFile) {
    try {
      await Vue.axios.post(`api/commands/file-management/file/convert-to-voucher`,  command, { responseType: 'json' });

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [MOVE_FILE]({ dispatch }, command: MoveFile) {
    try {
      await Vue.axios.post(`${baseURL()}/file-service/file/move`,  command, { responseType: 'json' });

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [DELETE_FILE]({ dispatch }, command: RenameFile) {
    try {
      await Vue.axios.post(`${baseURL()}/file-service/file/delete`,  command, { responseType: 'json' });

      return {};
    } catch (error) {
      return { error };
    }
  }
});

export default (instance: VueConstructor): Module<FileManagementState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});
