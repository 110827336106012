export interface File {
  id: string;
  filename: string;
  name: string;
  created: string;
  folderId: string;
  type: Type;
  mimeType: MimeType;
  conservatorship?: {
    id: string;
    conservatee: string;
  },
  owner?: {
    id: string;
    name: string;
  }
}
export interface SearchFile {
  id: string;
  filename: string;
  name: string;
  created: string;
  folder?: {
    id: string;
    path: string;
    name: string;
  },
  type: Type;
  mimeType: MimeType;
  conservatorship?: {
    id: string;
    conservatee: string;
  },
  owner?: {
    id: string;
    name: string;
  }
}

export interface SearchParams {
  employee_id?: string;
  conservatorship_id?: string;
  search?: string;
  date?: string;
}

export interface Folder {
  id: string;
  parentId?: string;
  rootId?: string;
  name: string;
  files: number;
  children: Folder[]
}

export interface FileManagementState {
  inbox: {
    files: File[],
    count: number,
    conservatorship: {
      [conservatorship: string]: File[]
    }
  },
  folder: {
    tree: {
      [conservatorship: string]: Folder[]
    }
  },
  files: {
    [folder: string]: File[]
  },
  outbox: {
    [folder: string]: File[]
  }
}

export interface CreateFolder {
  folder_id: string;
  parent_id?: string;
  name: string;
  conservatorship_id: string;
}

export interface RenameFolder {
  folder_id: string;
  name: string;
}

export interface CreateSubFolder {
  parent_id: string;
  folder_id: string;
  name: string;
}

export interface MoveFolder {
  parent_id: string | null;
  folder_id: string;
}

export interface DeleteFolder {
  folder_id: string;
  name: string;
}

export interface RenameFile {
  file_id: string;
  name: string;
}

export interface ConvertFile {
  file_id: string;
  transaction_id: string;
  finance_account_id: string;
}

export interface MoveFile {
  folder_id: string;
  file_id: string;
}

export interface DeleteFile {
  file_id: string;
  filename: string;
}

export enum MimeType {
  PDF = 'application/pdf',
  PNG = 'image/png',
  JPG = 'image/jpeg',

  WORD_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  WORD_DOC = 'application/msword',

  EXCEL_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  EXCEL_XLT = 'application/vnd.ms-excel',

  ODT = 'application/vnd.oasis.opendocument.text',
}

export enum Type {
  local = 'local',
  voucher = 'voucher',
  letter = 'letter',
}
